import React from "react";
import Layout from "./src/components/Layout";
import ReactDOM from "react-dom/client";


export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

export const wrapRootElement = ({ element, ...restProps }, ...args) => {
  return (
    <Layout
      name="wrapRootElement"
      props={restProps}
      args={args}
      mode="browser"
    >
      {element}
    </Layout>
  );
};