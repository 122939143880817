import React from "react";
import "../styles/global.css";

const Layout = ({ name, props, args, mode, style, children }) => {
  
  /*useEffect(() => {
    console.log(`${name} Layout mounted.`);
    console.log(
      `${name} Layout restProps:`,
      JSON.stringify(props, null, 2)
    );
    console.log(
      `${name} Layout arguments:`,
      JSON.stringify(args, null, 2)
    );
  }, []);*/

  //console.log(`${name} Layout rendered.`);
  return (
    <div className="layout" data-name={name} data-mode={mode} style={style}>
        {children}
    </div>
  );
};

export default Layout;